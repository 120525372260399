import React from "react";
import Topnav from "../../components/NavBar/Topnav";
import "../../pages/Config/styles.css";
import { useNavigate } from "react-router-dom";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Grid, Container, Box, TextField, Divider, IconButton, InputAdornment, OutlinedInput, CircularProgress, FormLabel, Tooltip } from '@mui/material';
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";
import * as C from "../Home/styles";

export default function Config(){

  let navigate  = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [config, setconfig] = React.useState({
    client_id: "",
    client_secret: "",
    nome_certificado: "",
    certificado: "",
    nome_chavePrivada: "",   
    chavePrivada: "",
    escopo: "",
    contaBancaria: "",
    dias_consulta: ""
  });

  async function fetchData (){

    const token = localStorage.getItem('user_token');
  
    const requestInfo = {
      method: 'GET',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/emitente/1", requestInfo)
      .then(resposta => resposta.json())
      .then((json) => {setconfig(json); setIsLoading(false); })
      .catch((error) => console.log(error));
  }

  React.useEffect(() => {
      //fetchData()
      setIsLoading(false);
  }, []);

  const editarconfig = async () => {

    console.log(config);

    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
      body: JSON.stringify(config)
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/config", requestInfo)
      .then(resposta => {
        if(resposta.ok){
          //console.log('Sucess')
          navigate('/home');
        }else{
          alert('Não foi possível atualizar as configurações')
        }
      })
  };

  const setCertificado = (e) => {
    setconfig({ ...config, nome_certificado: e.target.value, certificado: e.target.files[0]});
    console.log('entrou');
    console.log(e.target.value);
    console.log(e.target.files[0]);
  }

  const setChaveprivada = (e) => {
    setconfig({ ...config, nome_chavePrivada: e.target.value, chavePrivada: e.target.files[0]});
    console.log('entrou');
    console.log(e.target.value);
    console.log(e.target.files[0]);
  }

  return (
    <div>
      <Topnav/>
      <Container style={{width: '750px'}}>
      {isLoading ? (
        <C.Content>
        <Box>
          <CircularProgress />
        </Box>
        </C.Content>
          ) : (
        <Box component="form">    
            <h2 className="custom-title">Configurações</h2>
            <Divider/>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Boletos Banco Inter</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box>
                <TextField fullWidth label="Client ID" value={config.client_id === null ? '' : config.client_id} onChange={client_id => setconfig({ ...config, client_id: client_id.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row"> 
              <TextField fullWidth label="Client Secret" value={config.client_secret === null ? '' : config.client_secret} onChange={client_secret => setconfig({ ...config, client_secret: client_secret.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row"> 
              <TextField fullWidth label="Escopo" value={config.escopo === null ? '' : config.escopo} onChange={escopo => setconfig({ ...config, escopo: escopo.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Certificado Banco Inter (O nome deve ser: cert.crt)</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Tooltip title="Este é apenas o local de seleção do arquivo em seu computador, após confirmar ele será copiado para o servidor.">  
                <OutlinedInput fullWidth disabled value={config.nome_certificado === null ? '' : config.nome_certificado}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton color="inherit" aria-label="upload picture" component="label">
                        <input hidden accept="application/crt, .crt" type="file" onChange={e => setCertificado(e)}/>
                        <IoCloudUploadOutline />
                      </IconButton>
                    </InputAdornment>
                }>
                </OutlinedInput>
              </Tooltip>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Chave privada Banco Inter (O nome deve ser: chave.key)</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Tooltip title="Este é apenas o local de seleção do arquivo em seu computador, após confirmar ele será copiado para o servidor.">  
                <OutlinedInput fullWidth disabled value={config.nome_chavePrivada === null ? '' : config.nome_chavePrivada}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton color="inherit" aria-label="upload picture" component="label">
                        <input hidden accept="application/key, .key" type="file" onChange={e => setChaveprivada(e)}/>
                        <IoCloudUploadOutline />
                      </IconButton>
                    </InputAdornment>
                }>
                </OutlinedInput>
              </Tooltip>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField label="Conta Bancária" value={config.contaBancaria === null ? '' : config.contaBancaria} onChange={contaBancaria => setconfig({ ...config, contaBancaria: contaBancaria.target.value})}></TextField>
                <TextField label="Dias Consulta Pagamentos" value={config.dias_consulta === null ? '' : config.dias_consulta} onChange={dias_consulta => setconfig({ ...config, dias_consulta: dias_consulta.target.value})}></TextField>
              </Box>
            </Grid>

          <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh' }}
          onClick={() => {editarconfig()}}>
              Salvar
          </Accept>

          <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh' }}
          onClick={() => {navigate('/home')}}>
              Voltar
          </Back> 
        </Box>
        )}
      </Container>
    </div>
    )
  }