import './Topnav.css';
import { IoHome, IoBriefcase, IoBusinessSharp, IoPeopleSharp, IoSettingsSharp, IoExit } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";
import { AiTwotoneBank } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Tooltip } from '@mui/material';

const Topnav = () => {

    let navigate  = useNavigate();

    const { signout } = useAuth();

    const home = () => navigate('/home');
    const empresa = () => navigate('/empresa');
    const clientes = () => navigate('/clientes');
    const contratos = () => navigate('/contratos');
    const contasaReceber = () => navigate('/contasaReceber');
    const banco = () => navigate('/banco');
    const config = () => navigate('/config');

    return(
        <div className='topnav'>
            <Tooltip title="Página Inicial">
                <button className='button' type="button" onClick={home}>
                    <IoHome className='menuicon'></IoHome>
                </button>
            </Tooltip>
            <Tooltip title="Empresa">
                <button className='button' type="button" onClick={empresa}>
                    <IoBusinessSharp className='menuicon'/>
                </button>
            </Tooltip>
            <Tooltip title="Clientes">
                <button className='button' type="button" onClick={clientes}>
                    <IoPeopleSharp className='menuicon'/>
                </button>
            </Tooltip>
            <Tooltip title="Contratos">
                <button className='button' type="button" onClick={contratos}>
                    <IoBriefcase className='menuicon'/>
                </button>
            </Tooltip>
            <Tooltip title="Contas a receber">
                <button className='button' type="button" onClick={contasaReceber}>
                    <GiReceiveMoney className='menuicon'/>
                </button>
            </Tooltip>
            <Tooltip title="Banco">
                <button className='button' type="button" onClick={banco}>
                    <AiTwotoneBank className='menuicon'/>
                </button>
            </Tooltip>
            <Tooltip title="Configurações">
                <button className='button' type="button" onClick={config}>
                    <IoSettingsSharp className='menuicon'/>
                </button>
            </Tooltip>
            <Tooltip title="Sair">
                <button className='button' type="button" onClick={signout}>
                    <IoExit className='menuicon'/>
                </button>
            </Tooltip>
        </div>
    )
}

export default Topnav;