import React, { useState } from "react";
import "../../pages/Signin/styles.css";
import Logo from "../../components/Imagens/splash.png";
import * as C from "./styles";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { TextField, CircularProgress } from '@mui/material';
import Accept from "../../components/Button/accept";

const Signin = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  async function handleLogin () {

    if (!email && !senha) {
      setError("Preencha todos os campos");
      return;
    }

    if (!email) {
      setError("Preencha seu e-mail");
      return;
    }

    if (!senha) {
      setError("Preencha sua senha");
      return;
    }

    setIsLoading(true);

    const res = await signin(email, senha);

    if (!res) {
      setError('Dados incorretos');
      setIsLoading(false);
      return;
    }

    navigate("/home");
  };

  return (
    <C.Container>
      {/*<C.Label>SISCONTROL</C.Label>*/}
      <C.Content>
        <img src={Logo} width={250} height={250} alt="Logo"/>
        <TextField
          id="email"
          label="E-mail"
          type="email"
          placeholder="Digite seu E-mail"
          value={email}
          fullWidth
          onChange={(e) => [setEmail(e.target.value), setError("")]}
        />
        <TextField
          id="senha"
          label="Senha"
          type="password"
          placeholder="Digite sua Senha"
          value={senha}
          fullWidth
          onChange={(e) => [setSenha(e.target.value), setError("")]}
        />
        <C.labelError>{error}</C.labelError>
        <Accept className="btn-button" variant="contained" type="button" onClick={handleLogin} >
          Entrar
          {isLoading ? (
            <CircularProgress color="inherit" size="1rem" sx={{ marginLeft: 2 }} />
          ) : (
          <></>
          )}
        </Accept>
        {/*
        <C.LabelSignup>
          Não tem uma conta?
          <C.Strong>
            <Link to="/signup">&nbsp;Registre-se</Link>
          </C.Strong>
        </C.LabelSignup>
        */}
      </C.Content>
    </C.Container>
  );
};

export default Signin;
