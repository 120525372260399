import React from "react";
import Topnav from "../../components/NavBar/Topnav";
import DropdownEstados from "../../components/DropdownEstados/index";
import DropdownCidades from "../../components/DropdownCidades/index";
import "../../pages/Empresa/styles.css";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Box, TextField, Divider, Select, MenuItem, CircularProgress, FormLabel } from '@mui/material';
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";
import * as C from "../Home/styles";
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";

export default function Empresa(){

  let navigate  = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [openPopupToken, setOpenPopupToken] = React.useState(false);
  const [empresa, setEmpresa] = React.useState({
    cMun: "",
    cPais: "",
    cep: "",
    cnae: "",
    cnpj: "",
    cpf: "",
    crt: "",
    email: "",
    fone: "",
    ie: "",
    iest: "",
    im: "",
    nro: "",
    uf: "",
    xBairro: "",
    xCpl: "",
    xFant: "",
    xLgr: "",
    xMun: "",
    xNome: "",
    xPais: "",
    xSlogan: ""
  });

  async function fetchData (){

    const token = localStorage.getItem('user_token');
  
    const requestInfo = {
      method: 'GET',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/emitente/1", requestInfo)
      .then(resposta =>  {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => {setEmpresa(json); setIsLoading(false); })
      .catch((error) => console.log(error));
  }

  React.useEffect(() => {
      fetchData()
  }, []);

  const editarEmpresa = () => {
    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
      body: JSON.stringify(empresa)
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/emitente/1", requestInfo)
      .then(resposta => {
        if(resposta.ok){
          navigate('/home');
        }else{
          if (resposta.status === 401){
            setOpenPopupToken(true);
          }
          alert('Não foi possível atualizar empresa')
        }
      })        
  };

  const atualizaEstado = (e) => {
    setEmpresa({ ...empresa, uf: e})
  }

  const atualizaCidade = (e) => {
    setEmpresa({ ...empresa, cMun: e})
  }

  return (
    <div>
      <Topnav/>
      <Container style={{width: '750px'}}>
      {isLoading ? (
        <C.Content>
        <Box>
          <CircularProgress />
        </Box>
        </C.Content>
          ) : (
        <Box component="form">    
            <h2 className="custom-title">Empresa</h2>
            <Divider/>
            <PopUpTokenExpirado openPopup = {openPopupToken} />
            <Grid className="custom-row">
              <Box>
                <TextField fullWidth label="Razão Social" value={empresa.xNome === null ? '' : empresa.xNome} onChange={xNome => setEmpresa({ ...empresa, xNome: xNome.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row"> 
              <TextField fullWidth label="Fantasia" value={empresa.xFant === null ? '' : empresa.xFant} onChange={xFant => setEmpresa({ ...empresa, xFant: xFant.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row"> 
              <TextField fullWidth label="Slogan" value={empresa.xSlogan === null ? '' : empresa.xSlogan} onChange={xSlogan => setEmpresa({ ...empresa, xSlogan: xSlogan.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField label="CNPJ" value={empresa.cnpj === null ? '' : empresa.cnpj} onChange={cnpj => setEmpresa({ ...empresa, cnpj: cnpj.target.value})}></TextField>
                <TextField label="IE" value={empresa.ie === null ? '' : empresa.ie} onChange={ie => setEmpresa({ ...empresa, ie: ie.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField label="IM" value={empresa.im === null ? '' : empresa.im} onChange={im => setEmpresa({ ...empresa, im: im.target.value})}></TextField>
                <TextField label="IE ST" value={empresa.iest === null ? '' : empresa.iest} onChange={iest => setEmpresa({ ...empresa, iest: iest.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField label="CNAE" value={empresa.cnae === null ? '' : empresa.cnae} onChange={cnae => setEmpresa({ ...empresa, cnae: cnae.target.value})}></TextField>
                <Select fullWidth id="regime" label="Regime" value={empresa.crt === null ? '' : empresa.crt} onChange={crt => setEmpresa({ ...empresa, crt: crt.target.value})}>
                  <MenuItem key={1} value={1}>Simples Nacional</MenuItem>
                </Select>
              </Box>
            </Grid>

            <Grid className="custom-row"> 
              <TextField fullWidth label="Logradouro" value={empresa.xLgr === null ? '' : empresa.xLgr} onChange={xLgr => setEmpresa({ ...empresa, xLgr: xLgr.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <TextField label="Número" value={empresa.nro === null ? '' : empresa.nro} onChange={nro => setEmpresa({ ...empresa, nro: nro.target.value})}></TextField>
                <TextField label="Bairro" value={empresa.xBairro === null ? '' : empresa.xBairro} onChange={xBairro => setEmpresa({ ...empresa, xBairro: xBairro.target.value})}></TextField>
                <TextField label="CEP" value={empresa.cep === null ? '' : empresa.cep} onChange={cep => setEmpresa({ ...empresa, cep: cep.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <FormLabel>Estado</FormLabel>
                <FormLabel>Município</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <DropdownEstados id="estado" name="estado" title="estado" estado={empresa.uf} onChange={(ev) => atualizaEstado(ev.target.value)}></DropdownEstados>
                <DropdownCidades id="municipio" name="municipio" title="municipio" estado={empresa.uf} cidade={empresa.cMun} onChange={(ev) => atualizaCidade(ev.target.value)}></DropdownCidades>
              </Box>
            </Grid>

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField label="Complemento" value={empresa.xCpl === null ? '' : empresa.xCpl} onChange={xCpl => setEmpresa({ ...empresa, xCpl: xCpl.target.value})}></TextField>
                <TextField label="Telefone" value={empresa.fone === null ? '' : empresa.fone} onChange={fone => setEmpresa({ ...empresa, fone: fone.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row"> 
              <TextField fullWidth label="Email" value={empresa.email === null ? '' : empresa.email} onChange={email => setEmpresa({ ...empresa, email: email.target.value})}></TextField>
            </Grid>

          <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh' }}
          onClick={() => {editarEmpresa()}}>
              Salvar
          </Accept>

          <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh' }}
          onClick={() => {navigate('/home')}}>
              Voltar
          </Back> 
        </Box>
        )}
      </Container>
    </div>
    )
  }