import React from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import Iconify from '../Icon/Iconify';
import Close from "../Button/close";
import Accept from "../Button/accept";
import Back from "../Button/back";

export const PopupCancelarBoleto = (props) => {

    const {openPopupCancelar, setOpenPopupCancelar, boletoCancelar, setOpenPopupToken, setFiltro} = props;

    const cancelarBoleto = (nossonumero) => {
      const token = localStorage.getItem('user_token');
  
      const requestInfo = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }),
      };
      fetch("https://api-intercompi.siscontrol.app.br/api/cancelarboleto/" + nossonumero, requestInfo)
        .then(resposta => {
          if (resposta.status === 401){
            setOpenPopupToken(true);
          }
          return resposta.json();
        })
        .then((json) => {
          console.log(json);
          setFiltro('EM ABERTO');
          setOpenPopupCancelar(false);
        })
        .catch((error) => console.log(error));
    }

    const fechar = () => {
      setOpenPopupCancelar(false);
    }

    return(
      <div>
        <Dialog maxWidth="xlg" open={openPopupCancelar} style={{ height: 550 }}>
          <div>
            <DialogTitle>
              <div style={{display: 'flex'}}>
                <Typography variant="h6" component="div" style={{flexGrow: 1, width: 550}}>
                  Deseja realmente cancelar ?
                </Typography>
                <Close
                  onClick={() => fechar()}><Iconify icon="fa:close"/>
                </Close>
              </div>
            </DialogTitle>
            <DialogContent >    
              <Typography variant="body1" component="div" >
                    {boletoCancelar?.historico}, de {' '}
                    {boletoCancelar?.nome} no valor de R$ {boletoCancelar?.valor} {' '}
                    com vencimento para {boletoCancelar?.vencimento}.
              </Typography>
              <Accept variant="contained" type="button" style={{ marginTop: '2vh', height: '5vh' }}
              onClick={() => cancelarBoleto(boletoCancelar.nossonumero)}>
                  <Iconify icon="line-md:confirm" sx={{ width: 25, height: 25 }}/>
              </Accept> 
              <Back variant="outlined" type="button" style={{ marginTop: '2vh', marginLeft: '4vh', height: '5vh' }}
              onClick={() => fechar()}>
                  <Iconify icon="clarity:cancel-line" sx={{ width: 25, height: 25 }}/>
              </Back>
            </DialogContent>
          </div>
        </Dialog>
    </div>
    )
}