import { useEffect, useState } from 'react';
import Logo from '../../components/Imagens/marca.png';
import * as C from './styles';
import Topnav from '../../components/NavBar/Topnav';
import { Stack, Typography, TextField, Button } from '@mui/material';
import { PopUpTokenExpirado } from '../../components/Popup/popUpTokenExpirado';
import GraficoVendas from '../../components/Dashboard/graficoVendas';
import Iconify from '../../components/Icon/Iconify';

const Home = () => {
  const [openPopupToken, setOpenPopupToken] = useState(false);
  const [totalAnual, setTotalAnual] = useState('');
  const [config, setConfig] = useState({ id: 1, vencimentoCertificado: "" });

  useEffect(() => {

    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/retornaTotalAnual", requestInfo)
      .then(resposta => resposta.json())
      .then((json) => {
        setTotalAnual(json);
      })
      .catch((error) => console.log(error));

    fetch("https://api-intercompi.siscontrol.app.br/api/config", requestInfo)
      .then(resposta => resposta.json())
      .then((json) => {
        setConfig(json);
      })
      .catch((error) => console.log(error));
  }, []);

  const atualizarData = () => {

    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
      body: JSON.stringify(config)
    };

    console.log(config)

    fetch("https://api-intercompi.siscontrol.app.br/api/config/" + config.id, requestInfo)
      .then(resposta => {
        return resposta.json();
      })
      .then((json) => {
        setConfig(json);
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className="Home">
      <Topnav />
      <C.Date>
        <Typography variant="body1" color="inherit" style={{ marginRight: 25 }}>
          Validade do certificado:
        </Typography>
        <TextField size="small" type="date" value={config?.vencimentoCertificado} onChange={e => setConfig({ ...config, vencimentoCertificado: e.target.value })} />
        <Button color="inherit" style={{ marginRight: 20, height: 40 }} onClick={() => { atualizarData() }} startIcon={<Iconify icon="material-symbols:cloud-upload" style={{ marginLeft: 10 }} />}></Button>
      </C.Date>
      <C.Content>
        <Stack spacing={1} sx={{ mb: -10 }}>
          <Typography variant="h6" color="inherit">
            Faturamento
          </Typography>
          <Typography variant="h4">R$ {totalAnual.totalAnual}</Typography>
        </Stack>
        <GraficoVendas />
      </C.Content>
      <C.Content>
        <PopUpTokenExpirado openPopup={openPopupToken} />
        <img
          src={Logo}
          width={1000}
          height={200}
          alt="Logo"
        />
      </C.Content>
    </div>
  );
};

export default Home;
