import React from "react";
import { Select, MenuItem } from "@mui/material";

export default function DropdownCidades ({estado, cidade, onChange = () => {}}){

    const [cidades, setCidades] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {

        const token = localStorage.getItem('user_token');

        if (estado === '' || estado === undefined || estado === null){ 
          estado = 11;
          cidade = 1100015;
        }

        //console.log('recebeu estado'+estado+' cidade '+cidade)

        const requestInfo = {
          method: 'GET',
          headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }),
        };
  
        fetch("https://api-intercompi.siscontrol.app.br/api/municipios/"+estado, requestInfo)
          .then(resposta => resposta.json())
          .then((json) => {
              setCidades(json);
              setIsLoading(false);
            })
          .catch((error) => console.log(error));
    }, [estado]);

    return (
      <div>
          {isLoading ? (
            <div>Carregando...</div>
          ) : (
          <Select fullWidth id="cidade" label="Município" value={cidade ? cidade : ''} onChange={onChange}>
            {cidades.map((cidade) => { 
            return (
                    <MenuItem key={cidade.cmun} value={cidade.cmun}>{cidade.xmun}</MenuItem>
                   );
            })}
          </Select>
          )}
      </div>
    )
}