import React from "react";
import { useParams } from "react-router-dom";
import Topnav from "../../components/NavBar/Topnav";
import "../../pages/Clientes/styles.css";
import DropdownEstados from "../../components/DropdownEstados/index";
import DropdownCidades from "../../components/DropdownCidades/index";
import mask from "../../components/CpfCnpjMask/cpfCnpjMask";
import validarCpf from "../../components/CpfCnpjMask/validarCpfCnpj";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { Grid, Container, Box, TextField, Divider, FormLabel, Alert } from '@mui/material';
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";

export default function Cliente(){

  const {id} = useParams();
  let navigate  = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [cliente, setCliente] = React.useState({
    id: "", nome: "", cpf: "", rg: "", nascimento: "", endereco: "",
    cidade: "", estado: "", telefone: "", email: "", celular: "", bairro: "", informacoes: "", cep: "", cadastro: "", alteracao: "", orgaoexp: "",
    naturalidade: "", estcivil: "", numero: "", conjuge: "", pai: "", mae: "", telconju: "", telpai: "", telmae: "", empresa: "", cargo: "", telemp: "", salario: "",
    complemento: "", cobrador: "", classe: "", rgesposa: "", nascesposa: "", sexo: "", ativo: "", fantasia: "", im: "", codformapag: "", cpfesposa: "", contato: "", email2: "", desconto: ""
  });
  const [openPopupToken, setOpenPopupToken] = React.useState(false);
  const [status, setStatus] = React.useState({ type: '', mensagem: '' });
  const [cep, setCep] = React.useState();

  async function fetchData (){

    const token = localStorage.getItem('user_token');
  
    const requestInfo = {
      method: 'GET',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
    };

    await fetch("https://api-intercompi.siscontrol.app.br/api/cliente/"+id, requestInfo)
      .then(resposta => {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => {
                        setCliente(json);
                        setIsLoading(false);
                      })
      .catch((error) => console.log(error));
    
  }

  React.useEffect(() => {
    if (id !== undefined) {
      fetchData();
    }else{
      setIsLoading(false);
    }
  }, []);

  async function validate(){

    if (!validarCpf(cliente.cpf)){
      setStatus({
        type: 'error',
        mensagem: 'CPF/CNPJ Digitado é inválido!'
      });
      return false;      
    }

    let schema = yup.object().shape({
      nome: yup.string("Nome do cliente não está no padrão esperado!")
        .typeError("Nome do cliente não está no padrão esperado!")
        .required("Necessário preencher o nome do cliente!"),
      cpf: yup.string("CPF do cliente não está no padrão esperado!")
        .typeError("CPF do cliente não está no padrão esperado!")
        .required("Necessário preencher o CPF do cliente!"),
      endereco: yup.string("Endereço do cliente não está no padrão esperado!")
        .typeError("Endereço do cliente não está no padrão esperado!")
        .required("Necessário preencher o endereço do cliente!"),
      numero: yup.string("Número do cliente não está no formato esperado!")
        .typeError("Número do cliente não está no formato esperado!")
        .required("Necessário preencher o número do cliente!"),
      bairro: yup.string("Bairro do cliente não está no padrão esperado!")
        .typeError("Bairro do cliente não está no padrão esperado!")
        .required("Necessário preencher o bairro do cliente!"),
      cep: yup.string("CEP do cliente não está no formato esperado!")
        .typeError("CEP do cliente não está no formato esperado!")
        .required("Necessário preencher o CEP do cliente!"),
      estado: yup.number("Estado do cliente não está no formato esperado!")
        .typeError("Estado do cliente não está no formato esperado!")
        .required("Necessário preencher o estado do cliente!"),
      cidade: yup.number("Cidade do cliente não está no formato esperado!")
        .typeError("Cidade do cliente não está no formato esperado!")
        .required("Necessário preencher a cidade do cliente!"),
      email: yup.string("E-mail do cliente não está no formato esperado!")
        .typeError("E-mail do cliente não está no formato esperado!")
        .required("Necessário preencher o e-mail do cliente!")
    });

    try{
      await schema.validate(cliente);
      return true;
    }catch (err) {
      setStatus({
        type: 'error',
        mensagem: err.errors
      });
      return false;
    }
  }

  const cadastrarCliente = async () => {

    if(!(await validate())) return;

    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
      body: JSON.stringify(cliente)
    };

    if (id !== undefined) {
      fetch("https://api-intercompi.siscontrol.app.br/api/cliente/"+id, requestInfo)
        .then(resposta => {
          if(resposta.ok){
            navigate('/clientes');
          }else{
            if (resposta.status === 401){
              setOpenPopupToken(true);
            }
            alert('Não foi possível inserir Cliente')
          }
        })        
    }else{
      fetch("https://api-intercompi.siscontrol.app.br/api/cliente", requestInfo)
      .then(resposta => {
        if(resposta.ok){
          navigate('/clientes');
        }else{
          if (resposta.status === 401){
            setOpenPopupToken(true);
          }
          alert('Não foi possível inserir Cliente')
        }
      })         
    }
  };

  const atualizaEstado = (e) => {
    setCliente({ ...cliente, estado: e})
    console.log('setou estado'+e+' no cliente')
    console.log(cliente)
  }

  const atualizaCidade = (e) => {
    setCliente({ ...cliente, cidade: e})
  }

  function handleChangeMask(event) {
    const { value } = event.target

    setCliente({ ...cliente, cpf: mask(value)})
  }

  React.useEffect(() => {

    var value = cep + ''
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/(\d{5})(\d)/g, '$1-$2')
    value = value.replace(/(-\d{3})\d+?$/g, '$1')

    if(value === 'NaN') value = '';
  
    setCliente({ ...cliente, cep: value}); 
  
  }, [cep]);

  return (
    <div>
      <Topnav/>
      <Container style={{width: '750px'}}>          
          {isLoading ? (
            <h2 className="custom-title">Carregando...</h2>
          ) : (
          <Box component="form">
            <h2 className="custom-title">Cliente</h2>
            <Divider/>
            <PopUpTokenExpirado openPopup = {openPopupToken} />
            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Nome/Razão*</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box>            
                <TextField fullWidth variant="outlined" value={cliente.nome === null ? '' : cliente.nome} onChange={nome => setCliente({ ...cliente, nome: nome.target.value})}></TextField>
              </Box>   
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <FormLabel>CPF/CNPJ*</FormLabel>
                <FormLabel>RG/IE</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField value={cliente.cpf === null ? '' : cliente.cpf} onChange={cpf => handleChangeMask(cpf)}></TextField>

                <TextField value={cliente.rg === null ? '' : cliente.rg} onChange={e => setCliente({ ...cliente, rg: e.target.value})}></TextField>
              </Box>
            </Grid>  

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Data de Nascimento</FormLabel>
                <FormLabel>Telefone</FormLabel>
                <FormLabel>Celular</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <TextField type="date" value={cliente.nascimento === null ? '' : cliente.nascimento} onChange={e => setCliente({ ...cliente, nascimento: e.target.value})}/>

                <TextField label="Telefone" value={cliente.telefone === null ? '' : cliente.telefone} onChange={e => setCliente({ ...cliente, telefone: e.target.value})}></TextField>

                <TextField label="Celular" value={cliente.celular === null ? '' : cliente.celular} onChange={e => setCliente({ ...cliente, celular: e.target.value})}></TextField>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Endereço*</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <TextField fullWidth value={cliente.endereco === null ? '' : cliente.endereco} onChange={e => setCliente({ ...cliente, endereco: e.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Número*</FormLabel>
                <FormLabel>Bairro*</FormLabel>
                <FormLabel>CEP*</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <TextField value={cliente.numero === null ? '' : cliente.numero} onChange={e => setCliente({ ...cliente, numero: e.target.value})}></TextField>

                <TextField value={cliente.bairro === null ? '' : cliente.bairro} onChange={e => setCliente({ ...cliente, bairro: e.target.value})}></TextField>

                <TextField value={cliente.cep === null ? '' : cliente.cep} onChange={e => setCep(e.target.value)}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <FormLabel>Estado*</FormLabel>
                <FormLabel>Município*</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <DropdownEstados id="estado" label="Estado" estado={cliente.estado} onChange={(ev) => atualizaEstado(ev.target.value)}></DropdownEstados>

                <DropdownCidades id="municipio" label="municipio" title="municipio" estado={cliente.estado} cidade={cliente.cidade} onChange={(ev) => atualizaCidade(ev.target.value)}></DropdownCidades>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Email*</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
                <TextField fullWidth value={cliente.email === null ? '' : cliente.email} onChange={e => setCliente({ ...cliente, email: e.target.value})}></TextField>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <FormLabel>Email 2</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
                <TextField fullWidth value={cliente.email2 === null ? '' : cliente.email2} onChange={e => setCliente({ ...cliente, email2: e.target.value})}></TextField>
            </Grid>

            {status.mensagem.length > 0 &&
              <Grid className="custom-row">
                <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                  <Alert severity="error">{status.mensagem}</Alert>                           
                </Box>
              </Grid> 
            }
          
          <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh' }}
          onClick={() => {cadastrarCliente()}}>
              Salvar
          </Accept> 
          <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh' }}
          onClick={() => {navigate('/clientes')}}>
              Voltar
          </Back>
          </Box>
          )}
      </Container>
    </div>
    )
  }