import React, { useState } from "react";
import Topnav from "../../components/NavBar/Topnav";
import "./styles.css";
import Iconify from '../../components/Icon/Iconify';
import Accept from "../../components/Button/accept";
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { PopupBoletos } from "../../components/Popup/popUpBoletos";
import { Status } from "../../components/Status/Status";
import { styled } from '@mui/material/styles';
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";
import { PopupCancelarBoleto }  from "../../components/Popup/popUpCancelarBoleto";
import { IconButton as ImageButton, OutlinedInput, InputAdornment, Tooltip, Select, MenuItem, Grid, Box, Stack, Button, Typography, TextField, FormLabel } from '@mui/material';

export default function Contas() {

  const [contas, setcontas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filtro, setFiltro] = React.useState({status: "EM ABERTO", dataInicial: "", dataFinal: ""});
  const [openPopup, setOpenPopup] = useState(false);
  const [busca, setBusca] = React.useState('');
  const [openPopupToken, setOpenPopupToken] = React.useState(false);
  const [openPopupCancelar, setOpenPopupCancelar] = useState(false);
  const [boletoCancelar, setBoletoCancelar] = React.useState('');  
  const [dadosBoletos, setDadosBoletos] = React.useState([{
    id: "", historico: "", nome: "", telefone: "", emissao: "", vencimento: "", valor: "", valorrecebido: "", nossonumero: "", statusretorno: ""
  }]);
  const lowerBusca = busca.toLowerCase();

  const contasFiltrados = Array.isArray(contas) ? contas.filter((conta) => conta.nome?.toLowerCase().includes(lowerBusca)) : []

  React.useEffect(() => {
    filtrarContas();
  }, []);

  React.useEffect(() => {
    filtrarContas();
  }, [filtro.status]);

  const EnviarBoletos = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
    backgroundColor: '#58D68D',
    '&:hover': {
      backgroundColor: '#2ECC71',
      color: '#FFFFFF',
    },
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'historico', headerName: 'Histórico', width: 230 },
    { field: 'nome', headerName: 'Nome', width: 310 },
    { field: 'telefone', headerName: 'Telefone', width: 110 },
    { field: 'emissao', headerName: 'Emissão', width: 100 },
    { field: 'vencimento', headerName: 'Vencimento', width: 100 },
    { field: 'valor', headerName: 'Valor', width: 80 },
    { field: 'valorrecebido', headerName: 'Vl. Recebido', width: 100 },
    { field: 'nossonumero', headerName: 'Nosso número', width: 130 },
    {
      field: 'statusretorno', headerName: 'Status', width: 130,
      renderCell: (params) =>
        <Status
          color={(params.row.statusretorno === 'PAGO' && 'success')
              || (params.row.statusretorno === 'CANCELADO' && 'error')
              || (params.row.statusretorno === 'VENCIDO' && 'error')
              || (params.row.statusretorno === 'EXPIRADO' && 'error')
              || (params.row.statusretorno === 'EMABERTO' && 'warning')
              || (params.row.statusretorno === 'RECEBER' && 'info')}
        >
          {params.row.statusretorno === 'EMABERTO' ? 'EM ABERTO' : params.row.statusretorno}
        </Status>
    },
    {
      field: 'opcoes', headerName: 'Opções', width: 210,
      renderCell: (params) =>
        { return (params.row.statusretorno === 'EMABERTO') || (params.row.statusretorno === 'VENCIDO') ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Tooltip title="Verificar se boleto foi pago."><ImageButton onClick={() => { verificarBoleto(params.row.nossonumero); }}><Iconify icon="ant-design:file-search-outlined" /></ImageButton></Tooltip>
            <Tooltip title="Gerar PDF do boleto."><ImageButton onClick={() => { baixarBoleto(params.row.nossonumero); }}><Iconify icon="codicon:file-pdf" /></ImageButton></Tooltip>
            <Tooltip title="Reenviar e-mail de cobrança."><ImageButton onClick={() => { reenviarEmailCobranca(params.row.id); }}><Iconify icon="carbon:mail-all" /></ImageButton></Tooltip>
            <Tooltip title="Cancelar boleto."><ImageButton onClick={() => { abrirPopUpCancelar(params.row); }}><Iconify icon="icons8:cancel-2" /></ImageButton></Tooltip>
            {/*<Tooltip title="Editar conta."><ImageButton component={RouterLink} to={`/contareceber/${params.row.id}`}><Iconify icon="akar-icons:edit"/></ImageButton></Tooltip>
            <Tooltip title="Excluir conta."><ImageButton component={RouterLink} to={`/contareceber/${params.row.id}`}><Iconify icon="ep:delete"/></ImageButton></Tooltip>*/}
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
          </Stack>
        )}
    },
  ];

  const abrirPopUpCancelar = (params) => {
    setBoletoCancelar(params);
    setOpenPopupCancelar(true);
  };

  const verificarBoleto = (nossonumero) => {
    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    };
    fetch("https://api-intercompi.siscontrol.app.br/api/atualizarPagamentoBoleto/" + nossonumero, requestInfo)
      .then(resposta => {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => filtrarContas())
      .catch((error) => console.log(error));
  }

  const baixarBoleto = (nossonumero) => {
    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${token}`
      }),
    };

    fetch('https://api-intercompi.siscontrol.app.br/api/boletopdf/' + nossonumero, requestInfo)
      .then(resposta => {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        resposta.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = nossonumero + '.pdf';
          alink.click();
        })
          .catch((error) => console.log(error));
      })
  }

  const reenviarEmailCobranca = (id) => {
    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    };
    fetch("https://api-intercompi.siscontrol.app.br/api/reenviaremailcobranca/" + id, requestInfo)
      .then(resposta => {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => console.log(json))
      .catch((error) => console.log(error));
  }

  const executarRotinas = () => {
    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
    };
    fetch("https://api-intercompi.siscontrol.app.br/api/executarotinas", requestInfo)
      .then(resposta => {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => {
        console.log(json);
        setFiltro({...filtro, status: "EM ABERTO"});
      })
      .catch((error) => console.log(error));
  }

  const filtrarContas = () => {

    setLoading(true);
    
    const token = localStorage.getItem('user_token');

    const requestInfo = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
      body: JSON.stringify(filtro)
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/contasreceberfiltro", requestInfo)
      .then(resposta => {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        setLoading(false);
        return resposta.json();
      })
      .then((json) => {
        setcontas(json);
        //console.log(json);
      })
      .catch((error) => console.log(error));
  }

  return (
    <div style={{ height: 500, width: '100%' }}>
      <Topnav />
      <div style={{ height: 550, width: '90%', marginLeft: '80px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} marginTop="30px">
          <Typography variant="h4" gutterBottom>
            Contas a receber
          </Typography>
          <div >
            <Accept disabled={true} component={RouterLink} to="/contaReceber" startIcon={<Iconify icon="eva:plus-fill" />}>Adicionar</Accept>
            <EnviarBoletos style={{ marginLeft: '5px' }} onClick={() => { executarRotinas(); }} startIcon={<Iconify icon="bi:send-check" />}>Executar rotinas</EnviarBoletos>
            <EnviarBoletos disabled={true} style={{ marginLeft: '5px' }} onClick={() => { setOpenPopup(true); }} startIcon={<Iconify icon="bi:send-check" />}>Enviar Boletos</EnviarBoletos>
            <EnviarBoletos style={{ marginLeft: '5px' }} onClick={() => { setOpenPopup(true); }} startIcon={<Iconify icon="charm:refresh" />}>Consultar Pagamentos</EnviarBoletos>
          </div>
        </Stack>

        <PopUpTokenExpirado openPopup = {openPopupToken} />

        <Grid className="custom-space">
            <OutlinedInput placeholder="Pesquisar contas..."
            style={{width: 700}}
            value={busca}
            onChange={(ev) => setBusca(ev.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            } />

            <TextField type="date" style={{ marginLeft: 50 }} value={filtro.dataInicial === null ? '' : filtro.dataInicial} onChange={e => setFiltro({ ...filtro, dataInicial: e.target.value})}/>
            <FormLabel style={{ marginLeft: 10, marginRight: 10 }} >Até</FormLabel>
            <TextField type="date" style={{ marginRight: 10 }} value={filtro.dataFinal === null ? '' : filtro.dataFinal} onChange={e => setFiltro({ ...filtro, dataFinal: e.target.value})}/>      
            <Tooltip title="Filtrar contas por vencimento."> 
            <EnviarBoletos style={{ marginRight: 20, height: 40 }} onClick={() => { filtrarContas() }} startIcon={<Iconify icon="eva:search-fill" style={{ marginLeft: 10 }} />}></EnviarBoletos>     
            </Tooltip>
            <Select fullWidth id="formaPagamento" style={{ width: 300 }} value={filtro.status ? filtro.status : 'EM ABERTO'} onChange={e => {setFiltro({...filtro, status: e.target.value});}}>
              <MenuItem key={1} value={'EM ABERTO'}>EM ABERTO</MenuItem>
              <MenuItem key={2} value={'BOLETOS EM ABERTO'}>BOLETOS EM ABERTO</MenuItem>
              <MenuItem key={3} value={'CONTAS A RECEBER EM ABERTO'}>CONTAS A RECEBER EM ABERTO</MenuItem>
              <MenuItem key={4} value={'PAGO'}>PAGO</MenuItem>
              <MenuItem key={5} value={'CANCELADO'}>CANCELADO</MenuItem>     
              <MenuItem key={6} value={'TODOS'}>TODOS</MenuItem>
              <MenuItem key={7} value={'DATA DE PAGAMENTO'}>DATA DE PAGAMENTO</MenuItem>                   
            </Select>   

        </Grid> 

        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={contasFiltrados}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          loading={loading}
        />
      </div>
      <div >
        <PopupBoletos
          title="Boletos enviados"
          openPopup={openPopup}
          setDadosBoletos={setDadosBoletos}
          setOpenPopup={setOpenPopup}
          dadosBoletos={dadosBoletos}
        >
        </PopupBoletos>

        <PopupCancelarBoleto 
          openPopupCancelar={openPopupCancelar} 
          setOpenPopupCancelar={setOpenPopupCancelar} 
          boletoCancelar={boletoCancelar}
          setOpenPopupToken={setOpenPopupToken}
          setFiltro={setFiltro}
        >
        </PopupCancelarBoleto>
      </div>
    </div>
  );
}