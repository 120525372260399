import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import Iconify from '../../components/Icon/Iconify';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import Close from "../../components/Button/close";
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";

   const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'nome', headerName: 'Nome', width: 280 },
    { field: 'telefone', headerName: 'Telefone', width: 130 },
    { field: 'celular', headerName: 'Celular', width: 130 },
    { field: 'cpf', headerName: 'CPF/CNPJ', width: 150 },
    { field: 'rg', headerName: 'RG/IE', width: 130 },
    { field: 'nascimento', headerName: 'Nasc/Fund.', width: 130 },
    { field: 'endereco', headerName: 'Endereço', width: 250 },
    { field: 'numero', headerName: 'Número', width: 130 }
  ];

export const PopupClientes = (props) => {

    const {title, setDadosCliente, setContrato, contrato, dadosCliente, openPopup, setOpenPopup} = props;
    const [clientes, setClientes] = React.useState([]);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectionModel, setSelectionModel] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [busca, setBusca] = React.useState('');   
    const [error, setError] = useState("");
    const lowerBusca = busca.toLowerCase();

    const clientesFiltrados = Array.isArray(clientes) ?  clientes.filter((cliente) => cliente.nome.toLowerCase().includes(lowerBusca)) : []

    useEffect(() => {
        const token = localStorage.getItem('user_token');
  
        const requestInfo = {
          method: 'GET',
          headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }),
        };
  
        fetch("https://api-intercompi.siscontrol.app.br/api/clientes", requestInfo)
          .then(resposta => resposta.json())
          .then((json) => {
            setClientes(json);
            setIsLoading(false);
          })
          .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
    
      window.addEventListener('resize', handleWindowResize);
    
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

    const confirmaSelecao = () => {
      if (selectionModel.length === 0 || selectionModel.length > 1) {
        setError("Selecione um cliente!");
      }else{
        setError("");
        setContrato({ ...contrato, cliente_id: dadosCliente[0].id})
        setOpenPopup(false);
      }
    }

    const fechar = () => {
      setDadosCliente([{nome: ""}]);
      setOpenPopup(false);
      setError("");
    }
    
    return(
      <div>
      <Dialog maxWidth="xlg" open={openPopup} style={{ height: windowSize.innerHeight }}>
        {isLoading ? (
          <h2 className="custom-title">Carregando...</h2>
        ) : (
          <div>
            <DialogTitle>
                <div style={{display: 'flex'}}>
                    <Typography variant="h6" component="div" style={{flexGrow: 1}}>
                        {title}
                    </Typography>
                    <Close
                      onClick={() => fechar()}><Iconify icon="fa:close"/>
                    </Close>
                </div>
            </DialogTitle>
            <DialogContent >
                    <OutlinedInput 
                    fullWidth
                    placeholder="Pesquisar clientes..." 
                    style={{
                      marginBottom: '20px'
                    }}                
                    value={busca}
                    onChange={(ev) => setBusca(ev.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }/>
    
                  <DataGrid style={{ height: 480, width: 1500 }}
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  checkboxSelection ={true}
                  rows={clientesFiltrados} 
                  columns={columns}
                  pageSize={7}
                  rowsPerPageOptions={[7]}
                  loading={!clientes.length}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(selection) => {
                    if (selection.length > 1) {
                      const selectionSet = new Set(selectionModel);
                      const result = selection.filter((s) => !selectionSet.has(s));
                      setSelectionModel(result);
                      const selectedIDs = new Set(result);
                      const selectedRows = clientesFiltrados.filter((row) =>
                        selectedIDs.has(row.id)
                      );

                      setDadosCliente(selectedRows);
                    }else{ 
                      setSelectionModel(selection);
                      const selectedIDs = new Set(selection);
                      const selectedRows = clientesFiltrados.filter((row) =>
                        selectedIDs.has(row.id)
                      );

                      setDadosCliente(selectedRows);
                    }
                  }}
                  />
                  <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', height: '5vh' }}
                  onClick={() => confirmaSelecao()}>
                      <Iconify icon="line-md:confirm" sx={{ width: 25, height: 25 }}/>
                  </Accept> 
                  <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh', height: '5vh' }}
                  onClick={() => fechar()}>
                      <Iconify icon="clarity:cancel-line" sx={{ width: 25, height: 25 }}/>
                  </Back>
                  {error}
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
    )
}