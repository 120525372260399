import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, FormLabel, InputAdornment, OutlinedInput, Typography, CircularProgress, Box } from '@mui/material';
import Iconify from '../../components/Icon/Iconify';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import Close from "../../components/Button/close";
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";
import * as C from "./styles";

   const columns = [
    { field: 'historico', headerName: 'Histórico', width: 620 },
    { field: 'cliente', headerName: 'Cliente', width: 130 },
    { field: 'emissao', headerName: 'Emissao', width: 130 },
    { field: 'vencimento', headerName: 'Vencimento', width: 150 },
    { field: 'valor', headerName: 'Valor', width: 130 },
    { field: 'nossonumero', headerName: 'Nosso número', width: 160 },
    { field: 'statusretorno', headerName: 'Status', width: 150 }
  ];

export const PopupBoletos = (props) => {

    const {title, setDadosBoleto, dadosBoleto, openPopup, setOpenPopup} = props;
    const [boletos, setBoletos] = React.useState();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [selectionModel, setSelectionModel] = React.useState();
    const [isLoading, setIsLoading] = React.useState(true);
    const [busca, setBusca] = React.useState('');   
    const [error, setError] = useState("");
    const lowerBusca = busca.toLowerCase();

    const boletosFiltrados = Array.isArray(boletos) ? boletos.filter((Boleto) => Boleto.historico?.toLowerCase().includes(lowerBusca)) : []

    useEffect(() => {
      if (openPopup){
        enviarBoletos();
        //setIsLoading(false);
      }
    }, [openPopup]);

    const enviarBoletos = () => {
      const token = localStorage.getItem('user_token');
  
      const requestInfo = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
      };

      fetch("https://api-intercompi.siscontrol.app.br/api/gerareceber", requestInfo)
        .then(resposta => resposta.json())
        .then((json) => {
          setBoletos(json);
          setIsLoading(false);
        })
        .catch((error) => console.log(error));
    }

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
    
      window.addEventListener('resize', handleWindowResize);
    
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

    const confirmaSelecao = () => {
      setBoletos([]);
      setOpenPopup(false);
      setError("");
      //setContrato({ ...contrato, Boleto_id: dadosBoleto[0].id})
    }

    const fechar = () => {
      setBoletos([]);
      setOpenPopup(false);
      setError("");
    }
    
    return(
      <div>
      <Dialog maxWidth="xlg" open={openPopup} style={{ height: windowSize.innerHeight }}>
        {isLoading ? (
          <C.Content>
             <Typography variant="h6" gutterBottom>
             Enviando boletos, aguarde...
             <CircularProgress color="inherit" size="1rem" sx={{ marginLeft: 2 }} />
             </Typography>
          </C.Content>
        ) : (
          <div>
            <DialogTitle>
                <div style={{display: 'flex'}}>
                    <Typography variant="h6" component="div" style={{flexGrow: 1}}>
                        {title}
                    </Typography>
                    <Close
                      onClick={() => fechar()}><Iconify icon="fa:close"/>
                    </Close>
                </div>
            </DialogTitle>
            <DialogContent >
                <OutlinedInput 
                fullWidth
                placeholder="Pesquisar Boletos..." 
                style={{
                  marginBottom: '20px'
                }}                
                value={busca}
                onChange={(ev) => setBusca(ev.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }/>
    
                <DataGrid style={{ height: 480, width: 1500 }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection ={false}
                rows={boletosFiltrados} 
                columns={columns}
                pageSize={7}
                rowsPerPageOptions={[7]}
                loading={!boletos.length}
                selectionModel={selectionModel}
                />
                <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', height: '5vh' }}
                onClick={() => confirmaSelecao()}>
                    <Iconify icon="line-md:confirm" sx={{ width: 25, height: 25 }}/>
                </Accept> 
                <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh', height: '5vh' }}
                onClick={() => fechar()}>
                    <Iconify icon="clarity:cancel-line" sx={{ width: 25, height: 25 }}/>
                </Back>
                {error}
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
    )
}