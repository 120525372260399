import React from "react";
import { Select, MenuItem } from "@mui/material";

export default function DropdownEstados ({estado, onChange = () => {} }){

    const [estados, setEstados] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {

        const token = localStorage.getItem('user_token');

        const requestInfo = {
          method: 'GET',
          headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }),
        };
  
        fetch("https://api-intercompi.siscontrol.app.br/api/municipios", requestInfo)
          .then(resposta => resposta.json())
          .then((json) => {
            setEstados(json);
            setIsLoading(false);
          })
          .catch((error) => console.log(error));
    }, []);

    return (
        <div>
          {isLoading ? (
            <div>Carregando...</div>
          ) : (
            <Select fullWidth id="estado" label="UF" value={estado ? estado : '' } onChange={onChange}> 
              {estados.map((estado) => {
                return (
                <MenuItem key={estado.cuf} value={estado.cuf}>{estado.uf} - {estado.xuf}</MenuItem>
                );
              })}
            </Select>
          )}
        </div>
    )
}