import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Topnav from "../../components/NavBar/Topnav";
import "../../pages/ContasReceber/styles.css";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Box, Select, FormLabel, MenuItem, TextField, Divider, InputAdornment, IconButton, CircularProgress, Alert, Tooltip } from '@mui/material';
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";
import * as C from "./styles";
import * as yup from 'yup';
import { PopupClientes }  from "../../components/Popup/popUpClientes";
import SearchIcon from '@mui/icons-material/Search';
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";

export default function ContaReceber(){

  const {id} = useParams();
  let navigate  = useNavigate();
  var clicado = false;

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupToken, setOpenPopupToken] = React.useState(false);
  const [dadosCliente, setDadosCliente] = React.useState([{ id: "", nome: "", cpf: "", rg: "", nascimento: "", endereco: "", cidade: "", estado: "", telefone: "", email: "", celular: "", bairro: "", informacoes: "", cep: "", cadastro: "", alteracao: "", orgaoexp: "", naturalidade: "",
    estcivil: "", numero: "", conjuge: "", pai: "", mae: "", telconju: "", telpai: "", telmae: "", empresa: "", cargo: "", telemp: "", salario: "", complemento: "", cobrador: "", classe: "", rgesposa: "", nascesposa: "", sexo: "", ativo: "", fantasia: "", im: "", codformapag: "", cpfesposa: "", contato: "", email2: "", desconto: ""}]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [contaReceber, setContaReceber] = React.useState({ id: "", cliente_id: "", cliente: "", historico: "", dtEmissao: "", dtVencimento: "", documento: "", formaPagamento: 1, desconto: "", valor: "", contrato_id: "", juros: "", multa: "", status: 1, primeiraParcela: 1, xMotivoCancelamento: "", reajuste: "", tpReajuste: 1, freqCobranca: 1, diaCobranca: "", qtdRenovacoes: 0 });
  const [status, setStatus] = useState({ type: '', mensagem: '' });
  const [valor, setValor] = useState();
  const [desconto, setDesconto] = useState();
  const [juros, setJuros] = useState();
  const [multa, setMulta] = useState();
  const [dtEmis, setDtEmis] = useState();

  async function fetchData (){

    const token = localStorage.getItem('user_token');
  
    const requestInfo = {
      method: 'GET',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/contrato/"+id, requestInfo)
      .then(resposta =>  {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => {
        setContaReceber(json);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }

  React.useEffect(() => {
    if (id !== undefined) {
      fetchData();   
    } else {
        setDtEmis(getData())
    }
    setIsLoading(false);
  }, []);

  function getData(){
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    //var dataAtual = dia + '/' + mes + '/' + ano;
    var dataAtual = ano + '-' + mes + '-' + dia;
    //console.log(dataAtual)

    return dataAtual;
    //return dataAtual;
  }

  React.useEffect(() => {
    setContaReceber({ ...contaReceber, dtEmissao: dtEmis})
  }, [dtEmis]);

  React.useEffect(() => {
    setDadosCliente([{ ...dadosCliente, nome: contaReceber.cliente}]);
  }, [contaReceber.cliente]);

  React.useEffect(() => {
    clicado = true;
  }, [saveLoading]);

  const cadastrarContrato = async () => {

    if(!(await validate())) return;

    setSaveLoading(true);

    if(!clicado){
      const token = localStorage.getItem('user_token');
  
      const requestInfo = {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify(contaReceber)
      };
  
      if (id !== undefined) {
        fetch("https://api-intercompi.siscontrol.app.br/api/contrato/"+id, requestInfo)
          .then(resposta => {
            if(resposta.ok){
              //console.log(contrato)
              console.log(JSON.stringify(contaReceber))
              console.log('feito update')
              navigate('/contratos');
            }else{
              if (resposta.status === 401){
                setOpenPopupToken(true);
              }
              alert('Não foi possível inserir contrato')
            }
          })        
      }else{
        fetch("https://api-intercompi.siscontrol.app.br/api/contrato", requestInfo)
        .then(resposta => {
          console.log(resposta)
          if(resposta.ok){
            console.log('feito cadastro'+JSON.stringify(contaReceber))
            navigate('/contratos');
          }else{
            if (resposta.status === 401){
              setOpenPopupToken(true);
            }
            alert('Não foi possível inserir contrato')
          }
        })         
      }
    }
  };

  async function validate(){

    /* Se a primeira for boleto, a data de inicio de cobrança não pode ser menor que a data corrente
    if ((contaReceber.primeiraParcela === 1) && (contaReceber.dtInicioCobr < new Date().toLocaleString("en-CA",{ dateStyle: "short",timeZone: "America/Sao_Paulo"})) && (id === undefined)){
      setStatus({
        type: 'error',
        mensagem: 'Se a primeira parcela for boleto, a data da primeira cobrança não pode ser menor que a data de hoje.'
      });
      return false;
    }

    // Se tem data de ultimo pagamento, informar um motivo
    if ((contaReceber.dtPararCobranca) && (!contaReceber.xMotivoCancelamento)){
      setStatus({
        type: 'error',
        mensagem: 'Informe um motivo para o cancelamento.'
      });
      return false;
    }    

    // Se informou data de ultimo pagamento e motivo, forçar a alterar o status
    if ((contaReceber.dtPararCobranca) && (contaReceber.xMotivoCancelamento) && (contaReceber.status === 1)){
      setStatus({
        type: 'error',
        mensagem: 'Por favor, inative este contrato'
      });
      return false;
    }    

    // Se for inativar, precisa informar data e motivo
    if ((!contaReceber.dtPararCobranca) && (!contaReceber.xMotivoCancelamento) && (contaReceber.status === 0)){
      setStatus({
        type: 'error',
        mensagem: 'Por favor, informe uma data de último pagamento e um motivo para inativar.'
      });
      return false;
    }    */

    let schema = yup.object().shape({
      cliente_id: yup.number("Necessário preencher o cliente!")
        .typeError("Necessário preencher o cliente!")
        .required("Necessário preencher o campo cliente!"),
      dtEmissao: yup.date("Necessário preencher a data de emissão!")
        .typeError("Necessário preencher a data de emissão!")
        .required("Necessário preencher a data de emissão!"),
      dtVencimento: yup.date("Necessário preencher a data de vencimento!")
        .typeError("Necessário preencher a data de vencimento!")
        .required("Necessário preencher a data de vencimento!"),
      prazo: yup.number("Necessário preencher o campo prazo!")
        .typeError("Necessário preencher o campo prazo!")
        .required("Necessário preencher o campo prazo!"),
      diaCobranca: yup.number("Necessário preencher o dia da cobrança!")
        .typeError("Necessário preencher o dia da cobrança!")
        .required("Necessário preencher o dia da cobrança!"),
      dtInicioCobr: yup.date("Necessário preencher a data de início da cobrança!")
        .typeError("Necessário preencher a data de início da cobrança!")
        .required("Necessário preencher a data de início da cobrança!"),
      valor: yup.string("Necessário preencher o campo valor!")
        .typeError("Necessário preencher o campo valor!")
        .required("Necessário preencher o campo valor!"),
      reajuste: yup.string("Necessário preencher o campo valor do reajuste!")
        .typeError("Necessário preencher o campo valor do reajuste!")
        .required("Necessário preencher o campo valor do reajuste!")
    });

    try{
      await schema.validate(contaReceber);
      return true;
    }catch (err) {
      setStatus({
        type: 'error',
        mensagem: err.errors
      });
      return false;
    }
  }

  React.useEffect(() => {
    
    var value = valor + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
      value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if(value === 'NaN') value = '';

    setContaReceber({ ...contaReceber, valor: value});

  }, [valor]);

  React.useEffect(() => {
    
    var value = desconto + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
      value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if(value === 'NaN') value = '';

    setContaReceber({ ...contaReceber, desconto: value});

  }, [desconto]);

  React.useEffect(() => {
    
    var value = juros + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
      value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if(value === 'NaN') value = '';

    setContaReceber({ ...contaReceber, juros: value}); 

  }, [juros]);

  React.useEffect(() => {
    
    var value = multa + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
      value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if(value === 'NaN') value = '';

    setContaReceber({ ...contaReceber, multa: value}); 

  }, [multa]);

  return (
    <div>
    <div>
      <Topnav/>
      <Container style={{width: '750px'}}>          
          {isLoading ? (
            <C.Content>
            <Box>
              <CircularProgress />
            </Box>
            </C.Content>
          ) : (
          <Box component="form">
            <h2 className="custom-title">Conta a receber</h2>
            <Divider/>
            <PopUpTokenExpirado openPopup = {openPopupToken} />
            <Grid className="custom-row">
              <Box>
                <TextField
                   disabled
                   fullWidth
                   placeholder="Cliente"
                   value={dadosCliente[0]?.nome}
                   InputProps={{
                     endAdornment: (
                       <InputAdornment position="end">
                         <IconButton edge="end" onClick={() => setOpenPopup(true)}>
                            <SearchIcon />
                         </IconButton>
                       </InputAdornment>
                     ),
                   }}
                />
              </Box>   
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                  <FormLabel>Histórico</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <TextField fullWidth value={contaReceber.historico === null ? '' : contaReceber.historico} onChange={e => setContaReceber({ ...contaReceber, historico: e.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <Tooltip title="Data inicial do contrato, para registrar o histórico.">
                  <FormLabel>Data de Emissão</FormLabel>
                </Tooltip>
                <Tooltip title="Data de encerramento do contrato, que após o vencimento será renovada pela quantia do prazo (meses).">
                  <FormLabel>Data de Vencimento</FormLabel>
                </Tooltip>
                <FormLabel>Documento</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>

                <TextField type="date" value={contaReceber.dtEmissao === null ? '' : contaReceber.dtEmissao} onChange={e => setContaReceber({ ...contaReceber, dtEmissao: e.target.value})}/>
                
                <TextField type="date" value={contaReceber.dtVencimento === null ? '' : contaReceber.dtVencimento} onChange={e => setContaReceber({ ...contaReceber, dtVencimento: e.target.value})}/>

                <TextField value={contaReceber.documento === null ? '' : contaReceber.documento} onChange={e => setContaReceber({ ...contaReceber, documento: e.target.value})}></TextField>
                
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Forma de pagamento</FormLabel>
                <FormLabel>Desconto (R$ ou %)</FormLabel>
                <FormLabel>Valor (R$)</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <Select fullWidth id="formaPagamento" value={contaReceber.formaPagamento ? contaReceber.formaPagamento : '' } onChange={e => setContaReceber({ ...contaReceber, formaPagamento: e.target.value})}>
                    <MenuItem key={1} value={1}>Boleto</MenuItem>
                    <MenuItem key={2} value={2}>PIX</MenuItem>
                    <MenuItem key={3} value={3}>Pagam na Empresa</MenuItem>
                    <MenuItem key={4} value={4}>Cobramos</MenuItem>
                </Select>                

                <TextField value={contaReceber.desconto ? contaReceber.desconto : '' } onChange={e => setDesconto(e.target.value)}></TextField>
                <TextField value={contaReceber.valor ? contaReceber.valor : '' } onChange={e => setValor(e.target.value)}></TextField>

              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                <FormLabel>N. Contrato</FormLabel>
                <FormLabel>Juros (%)</FormLabel>
                <FormLabel>Multa (%)</FormLabel>
                <FormLabel>Status</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(4, 1fr)' }}>
                <TextField value={contaReceber.contrato_id ? contaReceber.contrato_id : '' } onChange={e => setContaReceber({ ...contaReceber, contrato_id: e.target.value})}></TextField>
                <TextField value={contaReceber.juros ? contaReceber.juros : '' } onChange={e => setJuros(e.target.value)}></TextField>
                <TextField value={contaReceber.multa ? contaReceber.multa : '' } onChange={e => setMulta(e.target.value)}></TextField>

                <Select fullWidth id="status" label="Status" value={contaReceber.status} onChange={status => setContaReceber({ ...contaReceber, status: status.target.value})}>
                  <MenuItem key={1} value={1}>Em aberto</MenuItem>
                  <MenuItem key={2} value={2}>Pago</MenuItem>
                  <MenuItem key={3} value={3}>Cancelado</MenuItem>
                </Select>                

              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Quantidade de renovações</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <TextField disabled={true} value={contaReceber.qtdRenovacoes === null ? 0 : contaReceber.qtdRenovacoes}></TextField>    
              </Box>
            </Grid>

            {status.mensagem.length > 0 &&
              <Grid className="custom-row">
                <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                  <Alert severity="error">{status.mensagem}</Alert>                           
                </Box>
              </Grid> 
            }
          
          <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh' }}
            onClick={() => {console.log(contaReceber)}}>
              Salvar
              {saveLoading ? (
                <CircularProgress color="inherit" size="1rem" sx={{ marginLeft: 2 }} />
              ) : (
                <></>
              )}
          </Accept> 
          <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh' }}
            onClick={() => {navigate('/contratos')}}>
              Voltar
          </Back>
          </Box>
          )}
      </Container>
      </div>
      <div >
      <PopupClientes 
        title = "Selecionar Cliente"
        openPopup = {openPopup}
        setDadosCliente = {setDadosCliente}
        setContaReceber = {setContaReceber}
        setOpenPopup = {setOpenPopup}
        contrato = {contaReceber}
        dadosCliente = {dadosCliente}
      >

      </PopupClientes>
      </div>
      </div>
    )
  }