import React from "react";
import Topnav from "../../components/NavBar/Topnav";
import "../../pages/Clientes/styles.css";
import Iconify from '../../components/Icon/Iconify';
import Accept from "../../components/Button/accept";
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";
import { PopupExcluirCliente }  from "../../components/Popup/popUpExcluirCliente";
import { IconButton as ImageButton, OutlinedInput, InputAdornment } from '@mui/material';
import { Stack, Typography } from '@mui/material';

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 500,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 1000, boxShadow: '#F5F5F5' },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

export default function Clientes(){

    const [clientes, setClientes] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [openPopupToken, setOpenPopupToken] = React.useState(false);
    const [openPopupExcluirCliente, setOpenPopupExcluirCliente] = React.useState(false);
    const [clienteExcluir, setClienteExcluir] = React.useState(''); 
    const [busca, setBusca] = React.useState('');   
    const lowerBusca = busca.toLowerCase();

    const clientesFiltrados = Array.isArray(clientes) ? clientes.filter((cliente) => cliente.nome.toLowerCase().includes(lowerBusca)) : []

    React.useEffect(() => {
      fetchData(); 
    }, []);

    async function fetchData (){
        const token = localStorage.getItem('user_token');
  
        const requestInfo = {
          method: 'GET',
          headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }),
        };
  
        fetch("https://api-intercompi.siscontrol.app.br/api/clientes", requestInfo)
          .then(resposta => {
             if (resposta.status === 401){
               setOpenPopupToken(true);
             }
             setLoading(false);
             return resposta.json();
          })
          .then((json) => setClientes(json))
          .catch((error) => console.log(error));
    }

    const abrirPopUpExcluirCliente = (params) => {
      setClienteExcluir(params);
      setOpenPopupExcluirCliente(true);
    };

    const columns = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'nome', headerName: 'Nome', width: 280 },
      { field: 'telefone', headerName: 'Telefone', width: 130 },
      { field: 'celular', headerName: 'Celular', width: 130 },
      { field: 'cpf', headerName: 'CPF/CNPJ', width: 150 },
      { field: 'rg', headerName: 'RG/IE', width: 130 },
      { field: 'nascimento', headerName: 'Nasc/Fund.', width: 130 },
      { field: 'endereco', headerName: 'Endereço', width: 250 },
      { field: 'numero', headerName: 'Número', width: 130 },
      { field: 'opcoes', headerName: 'Opções', width: 140, 
        renderCell: (params) => 
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ImageButton component={RouterLink} to={`/cliente/${params.row.id}`}><Iconify icon="akar-icons:edit"/></ImageButton> 
        <ImageButton onClick={() => { abrirPopUpExcluirCliente(params.row); }}><Iconify icon="ep:delete"/></ImageButton> 
        </Stack> },
    ];
  
    return (
      <div style={{ height: 500, width: '100%' }}>
            <Topnav/>
            <div style={{ height: 550, width: '90%', marginLeft: '80px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} marginTop="30px">
                <Typography variant="h4" gutterBottom>
                  Clientes
                </Typography>
                <Accept component={RouterLink} to="/cliente" startIcon={<Iconify icon="eva:plus-fill"/>}>Adicionar</Accept>
              </Stack>

              <PopUpTokenExpirado openPopup = {openPopupToken} />
              
              <SearchStyle placeholder="Pesquisar clientes..." 
              style={{
                marginBottom: '20px',
                marginTop: '-30px'
              }}                
              value={busca}
              onChange={(ev) => setBusca(ev.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }/>

              <DataGrid 
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={clientesFiltrados} 
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              loading={loading}
              />
            </div>
            <div>
            <PopupExcluirCliente
                  openPopupExcluirCliente={openPopupExcluirCliente} 
                  setOpenPopupExcluirCliente={setOpenPopupExcluirCliente} 
                  clienteExcluir={clienteExcluir}
                  setOpenPopupToken={setOpenPopupToken}
                  fetchData={fetchData}
                >
            </PopupExcluirCliente>
            </div>
      </div>
    );
}