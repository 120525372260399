import React from "react";
import Topnav from "../../components/NavBar/Topnav";
import "../../pages/Contratos/styles.css";
import Iconify from '../../components/Icon/Iconify';
import Accept from "../../components/Button/accept";
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Status } from "../../components/Status/Status";
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";
import { IconButton as ImageButton, OutlinedInput, InputAdornment } from '@mui/material';
import { Stack, Typography} from '@mui/material';

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'cliente', headerName: 'Cliente', width: 280 },
    { field: 'dtEmissao', headerName: 'Emissão', width: 130 },
    { field: 'dtVencimento', headerName: 'Vencimento', width: 130 },
    { field: 'prazo', headerName: 'Prazo', width: 80 },
    { field: 'reajuste', headerName: 'Reajuste', width: 80 },
    { field: 'tpReajuste', headerName: 'Tip. Reajuste', width: 80 },
    { field: 'diaCobranca', headerName: 'Dia Cob.', width: 130 },
    { field: 'tpCobranca', headerName: 'Tipo de Cobrança', width: 130 },
    { field: 'valor', headerName: 'Valor', width: 130 },
    { field: 'status', headerName: 'Status', width: 130, 
      renderCell: (params) =>
      <Status
        color={((params.row.status === '1' || params.row.status === 1) && 'success')
            || ((params.row.status === '0' || params.row.status === 0) && 'error')}
      >
        {params.row.status === '1' || params.row.status === 1 ? 'ATIVO' : 'INATIVO'}
      </Status>
    },
    { field: 'opcoes', headerName: 'Opções', width: 140, 
      renderCell: (params) => 
      <Stack direction="row" alignItems="center" justifyContent="space-between">
      <ImageButton component={RouterLink} to={`/contrato/${params.row.id}`}><Iconify icon="akar-icons:edit"/></ImageButton>
      </Stack> },
  ];

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 500,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 1000, boxShadow: '#F5F5F5' },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

export default function Contratos(){

    const [contratos, setContratos] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [openPopupToken, setOpenPopupToken] = React.useState(false);
    const [busca, setBusca] = React.useState('');   
    const lowerBusca = busca.toLowerCase();

    const contratosFiltrados = Array.isArray(contratos) ? contratos.filter((contrato) => contrato.cliente?.toLowerCase().includes(lowerBusca)) : []

    React.useEffect(() => {
        const token = localStorage.getItem('user_token');
  
        const requestInfo = {
          method: 'GET',
          headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }),
        };
        fetch("https://api-intercompi.siscontrol.app.br/api/contratos", requestInfo)
          .then(resposta => {
            if (resposta.status === 401){
              setOpenPopupToken(true);
            }
            setLoading(false);
            return resposta.json();
          })
          .then((json) => setContratos(json))
          .catch((error) => console.log(error));
    }, []);
  
    return (
      <div style={{ height: 500, width: '100%' }}>
            <Topnav/>
            <div style={{ height: 550, width: '90%', marginLeft: '80px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} marginTop="30px">
                <Typography variant="h4" gutterBottom>
                  Contratos
                </Typography>
                <Accept component={RouterLink} to="/contrato" startIcon={<Iconify icon="eva:plus-fill"/>}>Adicionar</Accept>
              </Stack>

              <PopUpTokenExpirado openPopup = {openPopupToken} />
              
              <SearchStyle placeholder="Pesquisar contratos..." 
              style={{
                marginBottom: '20px',
                marginTop: '-30px'
              }}                
              value={busca}
              onChange={(ev) => setBusca(ev.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }/>

              <DataGrid 
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={contratosFiltrados} 
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              loading={loading}
              />
            </div>
      </div>
    );
}