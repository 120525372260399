import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Topnav from "../../components/NavBar/Topnav";
import "../../pages/Contratos/styles.css";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container, Box, Select, FormLabel, MenuItem, TextField, Divider, InputAdornment, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Alert, Tooltip } from '@mui/material';
import Accept from "../../components/Button/accept";
import Back from "../../components/Button/back";
import * as C from "./styles";
import * as yup from 'yup';
import { PopupClientes }  from "../../components/Popup/popUpClientes";
import SearchIcon from '@mui/icons-material/Search';
import { PopUpTokenExpirado }  from "../../components/Popup/popUpTokenExpirado";

export default function Contrato(){

  const {id} = useParams();
  let navigate  = useNavigate();
  var clicado = false;

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupToken, setOpenPopupToken] = React.useState(false);
  const [dadosCliente, setDadosCliente] = React.useState([{ id: "", nome: "", cpf: "", rg: "", nascimento: "", endereco: "", cidade: "", estado: "", telefone: "", email: "", celular: "", bairro: "", informacoes: "", cep: "", cadastro: "", alteracao: "", orgaoexp: "", naturalidade: "",
    estcivil: "", numero: "", conjuge: "", pai: "", mae: "", telconju: "", telpai: "", telmae: "", empresa: "", cargo: "", telemp: "", salario: "", complemento: "", cobrador: "", classe: "", rgesposa: "", nascesposa: "", sexo: "", ativo: "", fantasia: "", im: "", codformapag: "", cpfesposa: "", contato: "", email2: "", desconto: ""}]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [contrato, setContrato] = React.useState({ id: "", cliente_id: "", cliente: "", status: 1, dtEmissao: "", prazo: 12, dtVencimento: "", dtCancelamento: "", dtPararCobranca: "", dtInicioCobr: "", primeiraParcela: 1, xMotivoCancelamento: "", valor: "", reajuste: "", tpReajuste: 1, freqCobranca: 1, diaCobranca: "", tpCobranca: 1, qtdRenovacoes: 0 });
  const [status, setStatus] = useState({ type: '', mensagem: '' });
  const [valor, setValor] = useState();
  const [reajuste, setReajuste] = useState();
  const [dtEmis, setDtEmis] = useState();

  async function fetchData (){

    const token = localStorage.getItem('user_token');
  
    const requestInfo = {
      method: 'GET',
      headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }),
    };

    fetch("https://api-intercompi.siscontrol.app.br/api/contrato/"+id, requestInfo)
      .then(resposta =>  {
        if (resposta.status === 401){
          setOpenPopupToken(true);
        }
        return resposta.json();
      })
      .then((json) => {
        setContrato(json);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }

  React.useEffect(() => {
    if (id !== undefined) {
      fetchData();   
    } else {
      setDtEmis(getData())
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    setDadosCliente([{ ...dadosCliente, nome: contrato.cliente}]);
  }, [contrato.cliente]);

  React.useEffect(() => {
    clicado = true;
  }, [saveLoading]);

  React.useEffect(() => {
    setContrato({ ...contrato, dtEmissao: dtEmis})
  }, [dtEmis]);

  function getData(){
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    //var dataAtual = dia + '/' + mes + '/' + ano;
    var dataAtual = ano + '-' + mes + '-' + dia;
    //console.log(dataAtual)

    return dataAtual;
    //return dataAtual;
  }

  const cadastrarContrato = async () => {

    if(!(await validate())) return;

    setSaveLoading(true);

    if(!clicado){
      const token = localStorage.getItem('user_token');
  
      const requestInfo = {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }),
        body: JSON.stringify(contrato)
      };
  
      if (id !== undefined) {
        await fetch("https://api-intercompi.siscontrol.app.br/api/contrato/"+id, requestInfo)
          .then(resposta => {
            if(resposta.ok){
              //console.log(contrato)
              console.log(JSON.stringify(contrato))
              console.log('feito update')
              navigate('/contratos');
            }else{
              if (resposta.status === 401){
                setOpenPopupToken(true);
              }
              alert('Não foi possível inserir contrato')
            }
          }).catch((error) => {
            alert('Não foi possível atualizar contrato ' + error );
          });         
      }else{
        await fetch("https://api-intercompi.siscontrol.app.br/api/contrato", requestInfo)
        .then(resposta => {
          console.log(resposta)
          if(resposta.ok){
            console.log('feito cadastro'+JSON.stringify(contrato))
            navigate('/contratos');
          }else{
            if (resposta.status === 401){
              setOpenPopupToken(true);
            }
            alert('Não foi possível inserir contrato')
          }
        }).catch((error) => {
          alert('Não foi possível inserir contrato ' + error );
        });         
      }
    }
  };

  async function validate(){

    // Se a primeira for boleto, a data de inicio de cobrança não pode ser menor que a data corrente
    if ((contrato.primeiraParcela === 1) && (contrato.dtInicioCobr < new Date().toLocaleString("en-CA",{ dateStyle: "short",timeZone: "America/Sao_Paulo"})) && (id === undefined)){
      setStatus({
        type: 'error',
        mensagem: 'Se a primeira parcela for boleto, a data da primeira cobrança não pode ser menor que a data de hoje.'
      });
      return false;
    }

    // Se tem data de ultimo pagamento, informar um motivo
    if ((contrato.dtPararCobranca) && (!contrato.xMotivoCancelamento)){
      setStatus({
        type: 'error',
        mensagem: 'Informe um motivo para o cancelamento.'
      });
      return false;
    }    

    // Se informou data de ultimo pagamento e motivo, forçar a alterar o status
    if ((contrato.dtPararCobranca) && (contrato.xMotivoCancelamento) && (contrato.status === 1)){
      setStatus({
        type: 'error',
        mensagem: 'Por favor, inative este contrato'
      });
      return false;
    }    

    // Se for inativar, precisa informar data e motivo
    if ((!contrato.dtPararCobranca) && (!contrato.xMotivoCancelamento) && (contrato.status === 0)){
      setStatus({
        type: 'error',
        mensagem: 'Por favor, informe uma data de último pagamento e um motivo para inativar.'
      });
      return false;
    }    

    let schema = yup.object().shape({
      cliente_id: yup.number("Necessário preencher o cliente!")
        .typeError("Necessário preencher o cliente!")
        .required("Necessário preencher o campo cliente!"),
      dtEmissao: yup.date("Necessário preencher a data de emissão!")
        .typeError("Necessário preencher a data de emissão!")
        .required("Necessário preencher a data de emissão!"),
      dtVencimento: yup.date("Necessário preencher a data de vencimento!")
        .typeError("Necessário preencher a data de vencimento!")
        .required("Necessário preencher a data de vencimento!"),
      prazo: yup.number("Necessário preencher o campo prazo!")
        .typeError("Necessário preencher o campo prazo!")
        .required("Necessário preencher o campo prazo!"),
      diaCobranca: yup.number("Necessário preencher o dia da cobrança!")
        .typeError("Necessário preencher o dia da cobrança!")
        .required("Necessário preencher o dia da cobrança!"),
      dtInicioCobr: yup.date("Necessário preencher a data de início da cobrança!")
        .typeError("Necessário preencher a data de início da cobrança!")
        .required("Necessário preencher a data de início da cobrança!"),
      valor: yup.string("Necessário preencher o campo valor!")
        .typeError("Necessário preencher o campo valor!")
        .required("Necessário preencher o campo valor!"),
      reajuste: yup.string("Necessário preencher o campo valor do reajuste!")
        .typeError("Necessário preencher o campo valor do reajuste!")
        .required("Necessário preencher o campo valor do reajuste!")
    });

    try{
      await schema.validate(contrato);
      return true;
    }catch (err) {
      setStatus({
        type: 'error',
        mensagem: err.errors
      });
      return false;
    }
  }

  React.useEffect(() => {
    
    var value = valor + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
      value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if(value === 'NaN') value = '';

    setContrato({ ...contrato, valor: value});

  }, [valor]);

  React.useEffect(() => {
    
    var value = reajuste + '';
    value = parseInt(value.replace(/[\D]+/g, ''));
    value = value + '';
    value = value.replace(/([0-9]{2})$/g, ",$1");

    if (value.length > 6) {
      value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if(value === 'NaN') value = '';

    setContrato({ ...contrato, reajuste: value}); 

  }, [reajuste]);

  return (
    <div>
    <div>
      <Topnav/>
      <Container style={{width: '750px'}}>          
          {isLoading ? (
            <C.Content>
            <Box>
              <CircularProgress />
            </Box>
            </C.Content>
          ) : (
          <Box component="form">
            <h2 className="custom-title">Contrato</h2>
            <Divider/>
            <PopUpTokenExpirado openPopup = {openPopupToken} />
            <Grid className="custom-row">
              <Box>
                <TextField
                   disabled
                   fullWidth
                   placeholder="Cliente"
                   value={dadosCliente[0]?.nome}
                   InputProps={{
                     endAdornment: (
                       <InputAdornment position="end">
                         <IconButton edge="end" onClick={() => setOpenPopup(true)}>
                            <SearchIcon />
                         </IconButton>
                       </InputAdornment>
                     ),
                   }}
                />
              </Box>   
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <Tooltip title="Data inicial do contrato, para registrar o histórico.">
                  <FormLabel>Data de Emissão</FormLabel>
                </Tooltip>
                <Tooltip title="Data de encerramento do contrato, que após o vencimento será renovada pela quantia do prazo (meses).">
                  <FormLabel>Data de Vencimento</FormLabel>
                </Tooltip>
                <Tooltip title="Quantidade de meses a renovar após vencimento do contrato.">
                  <FormLabel>Prazo (meses) para renovação</FormLabel>
                </Tooltip>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>

                <TextField type="date" value={contrato.dtEmissao === null ? '' : contrato.dtEmissao} onChange={e => setContrato({ ...contrato, dtEmissao: e.target.value})}/>
                
                <TextField type="date" value={contrato.dtVencimento === null ? '' : contrato.dtVencimento} onChange={e => setContrato({ ...contrato, dtVencimento: e.target.value})}/>

                <TextField value={contrato.prazo === null ? '' : contrato.prazo} onChange={e => setContrato({ ...contrato, prazo: e.target.value})}></TextField>
                
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Frequencia de Cobrança</FormLabel>
                <FormLabel>Tipo de Cobrança</FormLabel>
                <FormLabel>Dia da cobrança</FormLabel>
              </Box>
            </Grid> 
            
            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}> 
                <Select fullWidth id="frequencia" label="Frequencia" value={contrato.freqCobranca} onChange={e => setContrato({ ...contrato, freqCobranca: e.target.value})}>
                  <MenuItem key={1} value={1}>Mensal</MenuItem>
                  <MenuItem key={2} value={2}>Anual</MenuItem>
                </Select>
                <Select fullWidth id="tpCobranca" label="Tipo de cobrança" value={contrato.tpCobranca ? contrato.tpCobranca : '' } onChange={e => setContrato({ ...contrato, tpCobranca: e.target.value})}>
                  <MenuItem key={1} value={1}>Boleto</MenuItem>
                  <MenuItem key={2} value={2}>PIX</MenuItem>
                  <MenuItem key={3} value={3}>Pagam na Empresa</MenuItem>
                  <MenuItem key={4} value={4}>Cobramos</MenuItem>
                </Select>
                <TextField value={contrato.diaCobranca === null ? '' : contrato.diaCobranca} onChange={e => setContrato({ ...contrato, diaCobranca: e.target.value})}></TextField>
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <Tooltip title="Data de início da geração do á receber.">
                  <FormLabel>Data primeira da cobrança</FormLabel>
                </Tooltip>
                <Tooltip title="Indica se a primeira parcela será boleto, ou uma conta a receber normal.">
                  <FormLabel>Primeira parcela</FormLabel>
                </Tooltip>
                <FormLabel>Valor Mensal(R$)</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                
                <TextField type="date" value={contrato.dtInicioCobr === null ? '' : contrato.dtInicioCobr} onChange={e => setContrato({ ...contrato, dtInicioCobr: e.target.value})}/>

                <Select fullWidth id="primeiraParcela" value={contrato.primeiraParcela ? contrato.primeiraParcela : '' } onChange={e => setContrato({ ...contrato, primeiraParcela: e.target.value})}>
                  <MenuItem key={1} value={1}>Boleto</MenuItem>
                  <MenuItem key={2} value={2}>PIX</MenuItem>
                  <MenuItem key={3} value={3}>Pagam na Empresa</MenuItem>
                  <MenuItem key={4} value={4}>Cobramos</MenuItem>
                </Select>

                <TextField value={contrato.valor ? contrato.valor : '' } onChange={e => setValor(e.target.value)}></TextField>

              </Box>
            </Grid> 

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Tipo de reajuste</FormLabel>
                <FormLabel>Valor reajuste (R$ ou %)</FormLabel>
                <FormLabel>Status</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <Select fullWidth id="tpReajuste" label="Tipo de reajuste" value={contrato.tpReajuste} onChange={e => setContrato({ ...contrato, tpReajuste: e.target.value})}>
                  <MenuItem key={1} value={1}>Valor</MenuItem>
                  <MenuItem key={2} value={2}>%</MenuItem>
                </Select>
                <TextField value={contrato.reajuste ? contrato.reajuste : '' } onChange={e => setReajuste(e.target.value)}></TextField>

                <Select fullWidth id="status" label="Status" value={contrato.status} onChange={status => setContrato({ ...contrato, status: status.target.value})}>
                  <MenuItem key={1} value={1}>Ativo</MenuItem>
                  <MenuItem key={2} value={0}>Inativo</MenuItem>
                </Select>                

              </Box>
            </Grid>

            <Grid className="custom-row">
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <FormLabel>Quantidade de renovações</FormLabel>
              </Box>
            </Grid> 

            <Grid className="custom-row"> 
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                <TextField disabled={true} value={contrato.qtdRenovacoes === null ? 0 : contrato.qtdRenovacoes}></TextField>    
              </Box>
            </Grid>

            <Grid className="custom-row">
              <Accordion>
                 <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   aria-controls="panel1a-content"
                   id="panel1a-header"
                 >
                   <Typography>Dados cancelamento</Typography>
                 </AccordionSummary>
                 <AccordionDetails>
                   <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                     <FormLabel>Data último Pagamento</FormLabel>
                     <FormLabel>Data de Cancelamento</FormLabel>
                   </Box>

                   <Grid className="custom-row"> 
                    <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                      <TextField type="date" helperText="Gera um mês após o informado." value={contrato.dtPararCobranca === null ? '' : contrato.dtPararCobranca} onChange={e => setContrato({ ...contrato, dtPararCobranca: e.target.value})}></TextField>
                      <TextField disabled={true}  type="date" value={contrato.dtCancelamento === null ? '' : contrato.dtCancelamento}></TextField>
                    </Box>
                  </Grid>

                  <Grid className="custom-row"> 
                    <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                      <TextField fullWidth label="Motivo Cancelamento" value={contrato.xMotivoCancelamento === null ? '' : contrato.xMotivoCancelamento} onChange={e => setContrato({ ...contrato, xMotivoCancelamento: e.target.value})}></TextField>
                    </Box>
                  </Grid>

                 </AccordionDetails>
              </Accordion>
            </Grid> 

            {status.mensagem.length > 0 &&
              <Grid className="custom-row">
                <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                  <Alert severity="error">{status.mensagem}</Alert>                           
                </Box>
              </Grid> 
            }
          
          <Accept variant="contained" type="button" style={{ marginTop: '3vh', marginBottom: '4vh' }}
            onClick={() => {cadastrarContrato()}}>
              Salvar
              {saveLoading ? (
                <CircularProgress color="inherit" size="1rem" sx={{ marginLeft: 2 }} />
              ) : (
                <></>
              )}
          </Accept> 
          <Back variant="outlined" type="button" style={{ marginTop: '3vh', marginBottom: '4vh', marginLeft: '4vh' }}
            onClick={() => {navigate('/contratos')}}>
              Voltar
          </Back>
          </Box>
          )}
      </Container>
      </div>
      <div >
      <PopupClientes 
        title = "Selecionar Cliente"
        openPopup = {openPopup}
        setDadosCliente = {setDadosCliente}
        setContrato = {setContrato}
        setOpenPopup = {setOpenPopup}
        contrato = {contrato}
        dadosCliente = {dadosCliente}
      >

      </PopupClientes>
      </div>
      </div>
    )
  }